import './App.css';
import React from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/Home';
import Requests from './pages/requests/Requests';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <div className="App">
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/requests/:tenant/:hub/:button" element={<Requests />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}



export default App;
