import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const
  firebaseConfig = {
    apiKey: 'AIzaSyA9FS3UHvIHmaJo1ynooPARw-tMe2q7rzM',
    authDomain: 'swift-terminal-380919.firebaseapp.com',
    databaseURL: 'https://swift-terminal-380919-default-rtdb.firebaseio.com',
    projectId: 'swift-terminal-380919',
    storageBucket: 'swift-terminal-380919.appspot.com',
    messagingSenderId: '799158414597',
    appId: '1:799158414597:web:5ccdad1d7feda70abedb6c'
  },
  app = initializeApp(firebaseConfig);


export const db = getDatabase(app);
