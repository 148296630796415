import React from "react";
import Typography from '@mui/material/Typography';
import StyledTableHeaderRow from './StyledTableHeaderRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import StatusRow from "./StatusRow";

export default function RequestRowTable(props) {
  const { request } = props;
  return (
    <React.Fragment>
      <Typography variant="subtitle2" gutterBottom component="div" style={{ margin: '0 0 25px 50px' }}>
        Req. status entries
      </Typography>
      <Box style={{ margin: '0 50px  50px 50px' }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <StyledTableHeaderRow className="dark">
                <TableCell>#</TableCell>
                <TableCell>Date / time</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Recipient</TableCell>
                <TableCell>Trigger</TableCell>
                <TableCell>Trigger event</TableCell>
                <TableCell>Trigger type</TableCell>
                <TableCell>Data</TableCell>
              </StyledTableHeaderRow>
            </TableHead>
            <TableBody>
              {request.requestStatus.map((status, index) => (
                <StatusRow status={status} index={index} key={status.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>)
}