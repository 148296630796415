import React from "react";
import Collapse from '@mui/material/Collapse';
import StyledTableRow from './StyledTableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import Badge from '@mui/material/Badge';


import RequestRowCards from "./RequestRowCards";
import RequestRowTable from "./RequestRowTable";

export default function RequestRow(props) {
  const { request, buttonConfig } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {!request.requestWaMsgId && <QueueMusicIcon color="warning" />}
          {request.requestWaMsgId && request.requestWaReply === null && <WarningAmberOutlinedIcon className="blink" color="error" />}
          {request.requestWaMsgId && request.requestWaReply === 'Accepted' && <CheckOutlinedIcon color="success" />}
          {request.requestWaMsgId && request.requestWaReply === 'Rejected' && <ClearOutlinedIcon color="error" />}
          {request.requestWaMsgId && request.requestWaReply === 'Snoozed' && <QueryBuilderOutlinedIcon color="warning" />}
        </TableCell>
        <TableCell align="left">{request.requestCreatedAt}{request.requestParentId && (<React.Fragment><br /><em className="detail">snooze auto trigger</em></React.Fragment>)}</TableCell>
        <TableCell align="left">{buttonConfig.owner.data.firstName} {buttonConfig.owner.data.lastName}<br /><em className="detail">{buttonConfig.location?.description || ''} - {buttonConfig.zone?.description || ''}</em></TableCell>
        <TableCell align="left">{buttonConfig.recipientsSimplified.map((recipient, index) => (
          <React.Fragment key={index}><span className={index !== 0 && 'detail' || ''}>{recipient}</span><br /></React.Fragment>
        ))}</TableCell>
        <TableCell align="right">{request.requestWaStatus || '-'}</TableCell>
        <TableCell align="right">{request.requestWaReply || '-'}</TableCell>
      </StyledTableRow>
      <StyledTableRow className="hidden">
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="request-row-container">
              <div className="request-row-cards">
                <RequestRowCards request={request} />
              </div>
              <div className="request-row-table">
                <RequestRowTable request={request} />
              </div>
            </div>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment >
  );
}