import React from "react";
import Collapse from '@mui/material/Collapse';
import StyledTableRow from './StyledTableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

export default function StatusRow(props) {
  const { status, index } = props;
  const [dataOpen, setDataOpen] = React.useState(false);
  return (
    <React.Fragment>
      <StyledTableRow>
        <TableCell>{index + 1}.</TableCell>
        <TableCell>{status.createdAt}</TableCell>
        <TableCell>{status.description}</TableCell>
        <TableCell>{status.data && status.data.recipientName || '-'}</TableCell>
        <TableCell>{status.data && status.data.trigger && status.data.trigger.triggerName || '-'}</TableCell>
        <TableCell>{status.data && status.data.trigger && status.data.trigger.triggerEventName || '-'}</TableCell>
        <TableCell>{status.data && status.data.trigger && status.data.trigger.triggerType || '-'}</TableCell>
        <TableCell>
          {
            status.data && <IconButton size="small" onClick={() => setDataOpen(!dataOpen)}><MoreHorizOutlinedIcon /></IconButton>
          }
        </TableCell>
      </StyledTableRow>
      <StyledTableRow className="hidden">
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={dataOpen} timeout="auto" unmountOnExit>
            <Box style={{ margin: '25px' }}>
              <pre style={{ fontSize: '12px' }}>{status.formattedData || '-'}</pre>
            </Box>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  )
}