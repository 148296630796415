const
  _ = require('lodash'),
  moment = require('moment');
export const prepareRequestDataForScreen = (requestsObj) => {
  const getWaReplyText = (buttonText) => {
    let
      waReplyText = 'n/a';

    switch (buttonText) {
      case 'Accept':
        waReplyText = 'Accepted';
        break;
      case 'Resping':
        waReplyText = 'Rejected';
        break;
      case 'Amân':
        waReplyText = 'Snoozed';
        break;
    }
    return waReplyText;
  }

  let
    requestsArr = Object.keys(requestsObj).map((key, index) => {
      let
        request = requestsObj[key],
        requestId = key,
        requestParentId = request.parentId,
        requestEvents = request.events,
        requestMappingId = request.mappingId,
        requestStatus = !request.status ? [] : Object.keys(request.status).map((key, index) => {
          let
            statusEntry = request.status[key];

          statusEntry.id = key;
          statusEntry.createdAt = moment(key, 'YYYY-MM-DD HH:mm:ss.SSS Z').format('DD.MM.YYYY HH:mm:ss');
          if (statusEntry.data && statusEntry.data.trigger && statusEntry.data.trigger.triggerStatus) {
            statusEntry.formattedData = JSON.stringify(statusEntry.data.trigger.triggerStatus, null, 2);
          };
          if (statusEntry.data) {
            statusEntry.formattedData = JSON.stringify(statusEntry.data, null, 2);
          };
          return statusEntry;
        }),
        firstStatusEntry = requestStatus.find(status => status.description == 'Request created'),
        requestCreatedAt = firstStatusEntry ? moment(firstStatusEntry.id, 'YYYY-MM-DD HH:mm:ss.SSS Z').format('DD.MM.YYYY HH:mm:ss') : 'n/a',
        requestLastStatusDesc = requestStatus.length ? _.last(requestStatus).description : 'n/a',

        firstWaStatus = _(requestStatus).filter({ description: 'WhatsApp Status Update' }).first(),
        requestWaMsgId = firstWaStatus ? firstWaStatus.data?.MessageSid : null,

        lastWaStatus = _(requestStatus).filter({ description: 'WhatsApp Status Update' }).last(),
        requestWaStatus = lastWaStatus ? lastWaStatus.data.MessageStatus.charAt(0).toUpperCase() + lastWaStatus.data.MessageStatus.slice(1) : null,

        lastWaReply = _(requestStatus).filter({ description: 'WhatsApp Reply' }).last(),
        requestWaReply = lastWaReply ? getWaReplyText(lastWaReply.data.ButtonText) : null;

      return {
        requestCreatedAt,
        requestId,
        requestParentId,
        requestEvents,
        requestMappingId,
        requestStatus,
        requestLastStatusDesc,
        requestWaMsgId,
        requestWaStatus,
        requestWaReply
      }
    })

  return _.orderBy(requestsArr, ['requestCreatedAt'], 'desc');
}

export const prepareButtonConfigDataForScreen = buttonConfig => {
  let
    enhancedButtonConfig = buttonConfig,
    recipientsSimplified = buttonConfig.recipients.map(recipient => {
      switch (recipient.type) {
        case 'person':
          return `${recipient.data.firstName || ''} ${recipient.data.lastName || ''} (+${recipient.data.waNumber || 'no Whatsapp number defined'})`

        case 'device':
          return `${recipient.data.type} (${recipient.data.description})`
      }
    });
  enhancedButtonConfig.recipientsSimplified = recipientsSimplified;

  return enhancedButtonConfig;
}