import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';

const StyledTableHeaderRow = styled(TableRow)(({ theme }) => ({
  '&': {
    backgroundColor: theme.palette.warning.main,
  },
  '&.dark': {
    backgroundColor: theme.palette.grey['800'],
  },
  '& td, & th': {
    color: theme.palette.common.white
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default StyledTableHeaderRow;