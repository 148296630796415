import React from "react";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export default function RequestRowCards(props) {
  const { request } = props,
    cardWidths = {
      width: {
        xs: "90%",
        sm: "90%",
        md: "100%",
        lg: "400px",
        xl: "400px"
      },
    };
  return (
    <React.Fragment>
      <Card sx={cardWidths}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Req. created at
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {request.requestCreatedAt}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Req. mapping ID
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {request.requestMappingId}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={cardWidths}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Req. events
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {`${request.requestEvents.join(' / ')}`}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Req. ID
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {request.requestId}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={cardWidths}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Whatsapp message ID
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {`${request.requestWaMsgId || 'n/a'}`}
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}