import './Home.css';
import headerImage from '../images/home.png';

function Home({ styles }) {
  return (
    < div className="home" >
      <div className='home-logo'></div>
      <div className='home-intro'>
        <div className='home-intro-header'><img src={headerImage} /></div>
        <div className='home-intro-content'>
          {/* <h1>So many toys, so little time!</h1> */}
          <h1 className='t-white'>app.smart-connect.io</h1>
          {/* <p>Traim intr-o lume care inventeaza tehnologii pe repede-nainte fara a putea crea in acelasi ritm si timpul necesar pentru a se folosi de ele.</p>
          <p>Noi - <em className='t-white'>Smart Connect</em> - credem ca tehnologia trebuie folosita pentru a creste calitatea vietii prin solutii de comunicatii simple, intuitive si accesibile, bazate pe grija si responsabilitate fata de toti cei care au nevoie de ele.</p>
          <p>Conexiunea tine oamenii impreuna, in siguranta si control, iar comunicarea devine simpla ca o atingere.<br />
            <em className='t-white'>O atingere de buton!</em></p>
          <a href="mailto:connect@smart-connect.io" className="button">Let's connect!</a> */}
        </div>
      </div>

    </div >
  );
}

export default Home;