
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import './Requests.css';
import RequestRow from "./components/RequestRow";
import StyledTableHeaderRow from "./components/StyledTableHeaderRow";

import { db } from '../../utils/firebase';
import { get, child, onValue, ref } from 'firebase/database';
import { prepareRequestDataForScreen, prepareButtonConfigDataForScreen } from "../../utils/utils";

import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

function Requests() {
  const [requests, setRequests] = useState([]);
  const [buttonConfig, setButtonConfig] = useState(null);
  const { tenant, hub, button } = useParams();

  useEffect(() => {
    fetch(`https://api.smart-connect.io/api/v0/config/${tenant}/${hub}/${button}`)
      .then((res) => res.json())
      .then((data) => {
        setButtonConfig(prepareButtonConfigDataForScreen(data.buttonConfig));
      })
      .catch((err) => {
        console.log(err.message);
      });


    return onValue(ref(db, `tenants/${tenant}/hubs/${hub}/buttons/${button}/requests`), (snapshot) => {
      if (snapshot.exists()) {
        setRequests(prepareRequestDataForScreen(snapshot.val()));
      } else {
        console.error('Snapshot does not exist!')
      }
    });
  }, []);

  const
    theme = useTheme();

  return (
    buttonConfig ? (
      <Box sx={{ margin: 3 }} >
        <div className="requests">
          <div className="requests-header">
            <div className='requests-logo'></div>
            <Typography variant="h4" align="right" gutterBottom component="div" style={{ padding: '10px', margin: 0, color: theme.palette.warning.main }}>
              app.smart-connect.io
            </Typography>
          </div>
          <div className="requests-content">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <StyledTableHeaderRow>
                    <TableCell colSpan={2} align="left"><Typography variant="subtitle1" sx={{ backgroundColor: 'white', color: theme.palette.warning.main, textAlign: 'center' }}>{button}</Typography></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">Owner / Location</TableCell>
                    <TableCell align="left">Recipients</TableCell>
                    <TableCell align="right">Whatsapp message status</TableCell>
                    <TableCell align="right">Whatsapp reply status</TableCell>
                  </StyledTableHeaderRow>
                </TableHead>
                <TableBody>
                  {requests.map((request) => (
                    <RequestRow key={request.requestId} request={request} buttonConfig={buttonConfig} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div >
      </Box >) : (
      <LinearProgress color="warning" />
    )
  );
}

export default Requests;