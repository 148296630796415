import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&': {
    backgroundColor: theme.palette.common.primary,
  },
  '& td, & th': {
    //border: '1px solid red'    
  },
  '&.hidden > td': {
    borderBottom: 0
  },
  // hide last border
  '&:last-child > td, &:last-child > th': {
    border: 0
  },
}));

export default StyledTableRow;